import React, { useEffect } from "react";
import Layout from "../components/layouts";


const Faqs = () => {

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          panel.style.marginTop = "0px";
          panel.style.paddingBottom = "0px";
        } else {
          panel.style.maxHeight = panel.scrollHeight + 16 + "px";
          panel.style.marginTop = "16px";
          panel.style.paddingBottom = "32px";
        } 
      });
    }
  }, [])

  return (
    <Layout>
      <section className="w-100 bg-rich-black pos-r desired-height-3rd">
        <div className="main-wrap mx-auto d-flx flex-dir-col j-c-c h-100 pos-r z-depth-3">
          <div className="wrapper-yt will-grow">
            <div className="maxwidth-mt mx-auto tx-c wrapper will-grow ">
              <h1 className="co-white very-largetext">
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="w-100">
        <div className="maxwidth-sl mx-auto wrapper-x wrapper-y will-grow">
          <article className="wrapper-yt">
            <h3 className="very-largetext">General</h3>
            <div className="wrapper-y">
              <button className="accordion">Do I need to be a UK resident?</button>
              <div className="panel">
                <p>No, ROAVA also accepts residency if you live in Nigeria or the UK.</p>
              </div>
              <button className="accordion">How old do I have to be?</button>
              <div className="panel">
                <p>You must be 18 or older to get a ROAVA account. </p>
              </div>
            </div>
          </article>
          <article className="wrapper-yt">
            <h3 className="very-largetext">Roava</h3>
            <div className="wrapper-y">
              <button className="accordion">Is ROAVA a bank?</button>
              <div className="panel">
                <p>ROAVA is a trading name of First City Monument UK Bank (FCMB UK) which is authorised by the Prudential Regulation Authority and is reglated by both the Financial Conduct Authority and the Prudential Regulation Authority.</p>
              </div>
              <button className="accordion">How do I get a ROAVA account?</button>
              <div className="panel">
                <p>ROAVA is a trading name of First City Monument UK Bank (FCMB UK) which is authorised by the Prudential Regulation Authority and is reglated by both the Financial Conduct Authority and the Prudential Regulation Authority</p>
              </div>
            </div>
          </article>
          <article className="wrapper-yt">
            <h3 className="very-largetext">Security</h3>
            <div className="wrapper-y">
              <button className="accordion">Is my money safe with ROAVA?</button>
              <div className="panel">
                <p>Your money is fully protected by the Financial Services Compensation Scheme (FSCS) for your GBP account, meaning that all your money up to £85,000 is guaranteed by the British Government.</p>
              </div>
            </div>
          </article>
          <article className="wrapper-yt">
            <h3 className="very-largetext">Withdrawals</h3>
            <div className="wrapper-y">
              <button className="accordion">How do I get started?</button>
              <div className="panel">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
            </div>
          </article>
          <article className="wrapper-yt">
            <h3 className="very-largetext">Fees &amp; Limits</h3>
            <div className="wrapper-y">
              <button className="accordion">What fees do I get charged?</button>
              <div className="panel">
                <p>We charge fees for some of our services, depending on how you use your account. There's more information in our Fee Information document.</p>
              </div>
            </div>
          </article>

          <article className="wrapper-yt">
            <h3 className="very-largetext">Complaints</h3>
            <div className="wrapper-y">
              <button className="accordion">Do you want an answer?</button>
              <div className="panel">
                <p>"If you would like to make a complaint, please get in touch and we will look into this as soon as possible.
                </p>
                <p>
              If you are a customer, you can send us a message through the App Chat.
              Email us at <a href="mailto:support@roava.app">support@roava.app</a> 
              </p>
                <p>
              What happens next?
              We will be in touch within 3 business days to try and resolve your issue for you. If we are unable to resolve your complaint in this time, you should know that we're typically allowed up to 8 weeks to investigate a complaint and produce what is referred to as our final response.
              </p>
                <p>
              If you are unhappy with our final response, you have the right to refer your complaint to the Financial Ombudsman Service, free of charge – but you must do so within six months of the date of our final response.
              </p>
                <p>
              The Ombudsman’s address is:<br/>
              Financial Ombudsman Service,<br/>
              Exchange Tower,<br/>
              London,<br/>
              E14 9SR
              </p>
                <p>
              They can be reached by phone at 0800 023 4567 or 0300 123 9123 . Or, you can visit their website.
              </p>
                <p>
              You can also see an online version of their consumer leaflet.
              </p>
                <p>
              Don't want to complain but want to give us feedback?
              Please share your thoughts in the Community Forum or via the App Chat and we'll ensure this information is passed on to the relevant team."</p>
              </div>
            </div>
          </article>

        </div>
      </section>

    </Layout>
  );
};

export default Faqs;
